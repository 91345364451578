// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@ionic/angular/css/display.css';


html,
body,
ion-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ion-toolbar {
  padding-bottom: 0;

  ion-title {
    padding-top: 3px;
  }
}

ion-item.item-input,
ion-select {
  /*
  border-bottom: 2px dashed #ccc;

  &.ion-pristine {
    border-bottom: 2px dashed #ccc;
    //border-bottom: inherit;
    //border-bottom: 2px dashed rgba(255, 0, 0, 0.51);
  }

  margin-bottom: .25em;
  */
}

ion-item {
  --ion-item-background: transparent;

  ion-text {
    //color: #000 !important;
    font-weight: bold;
  }

  ion-label {
    text-transform: uppercase;
    font-weight: bold;

    h2 {
      font-weight: bold !important;
    }

    p {
      font-weight: normal
    }
  }

  @media (min-width: 576px) {
    font-size: 1.4em;

    ion-label {
      font-size: 17px !important;
    }
  }

  small {
    font-size: 1em;
  }
}

@media (min-width: 576px) {
  ion-note {
    font-size: .9em;
  }
}

ion-item-divider {
  --background: transparent;
  font-size: 1.43em;
  color: #666;
  font-weight: bold;


}

ion-list {
  --ion-item-background: transparent;

  ion-item.button {
    background: #fff;
    color: #000;
    --padding-start: .8em;
    --border-radius: .25em;
    --padding-top: .3em;
    --padding-bottom: .3em;
    --ripple-color: var(--ion-color-primary);
    margin-top: .25em;
    border-radius: .25em;
    --ion-color-step-400: #222;
    --ion-color-step-350: #222;
  }

  ion-item.button:nth-child(even) {
    background: #fff8e9;
  }

  @media (min-width: 576px) {
    ion-label {
      p {
        font-size: 1.3em !important;
      }

      h2 {
        font-size: 1.5em !important;
      }
    }
  }

}

@media (min-width: 576px) {
  ion-segment {

    ion-segment-button {
      font-size: 1.65em;
      padding: .25em;


    }
  }
}

ion-content {
  --background: var(--ion-color-light);
}

.logo-header {
  height: 30px;
  float: right;
}

ion-note {
  ion-badge {
    margin-top: .25em;
    font-size: 1.1em;
  }
}

ion-item.remove-bottom-padding {
  --inner-padding-bottom: 0;
  --min-height: 0;
  margin-bottom: 0;

  ion-label {
    margin-bottom: 0;
  }
}

.label,
ion-label {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: .25em;

  span {
    font-weight: normal;
    text-transform: none;
    font-size: .95em;
  }

  @media (min-width: 576px) {
    font-size: 17px;
  }
}

// workaround: full screen action sheet scrolling background on iOS
.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}

// workaround: action sheet not honoring safe area margins
.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}

ion-action-sheet {
  --max-width: 600px !important;
  --color: #333 !important;
  font-size: 12px;

  .action-sheet-button.sc-ion-action-sheet-ios {
    padding-top: 5px;
    padding-bottom: 0;
    //height: 50px;

    .action-sheet-button-inner.sc-ion-action-sheet-ios {
      font-size: 16px;
      line-height: 1.3em;
      display: block;
      //white-space: nowrap;
    }
  }


}

@media (min-height: 700px) {
  ion-modal.barcodeScanner {
    --height: 750px;
  }
}

@media (min-height: 768px) {
  ion-modal.barcodeScanner {
    --width: 750px;
  }
}