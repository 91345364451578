/** Ionic CSS Variables **/
:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-color-step-50: #fefefe;
  --ion-color-step-100: #fbfdfb;
  --ion-color-step-150: #fafafa;
  --ion-color-step-200: #cacaca;
  --ion-color-step-250: #bababa;
  --ion-color-step-300: #cccccc;
  --ion-color-step-350: #707070;
  --ion-color-step-400: #636363;
  --ion-color-step-450: #585858;
  --ion-color-step-500: #575757;
  --ion-color-step-550: #555555;
  --ion-color-step-600: #515151;
  --ion-color-step-650: #484848;
  --ion-color-step-700: #424242;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;

  /** primary **/
  --ion-color-primary: #f7961e;
  --ion-color-primary-rgb: 47, 50, 160;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #9e5f11;
  --ion-color-primary-tint: #e0a864;

  /** secondary **/
  --ion-color-secondary: #f1be75;
  --ion-color-secondary-rgb: 91, 95, 222;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: rgb(177, 127, 52);
  --ion-color-secondary-tint: rgb(236, 175, 105);

  /** tertiary **/
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244, 169, 66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;

  /** success **/
  --ion-color-success: #b8e994;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ff5e00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  /** light **/
  --ion-color-light: #fff8e9;
  --ion-color-light-rgb: 253, 238, 206;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e2a769;
  --ion-color-light-tint: #f8cc94;

  /** medium **/
  --ion-color-medium: #f2f1f1;
  --ion-color-medium-rgb: 242, 241, 241;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** dark **/
  --ion-color-dark: #3c3b3a;
  --ion-color-dark-rgb: 60, 59, 58;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #60460e;
  --ion-color-dark-tint: #6d5625;

}